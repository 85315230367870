/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

/*global player*/


(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        var showSearch = function () {
          $('body').addClass("active-search");
          $(".search-field").focus();
        };
        var hideSearch = function () {
          $('body').removeClass("active-search");
        };

        $(function () {
          var find = $('.utility-menu.icon-search');
          var close = $('.search-form-container .icon-cancel');
          $(find).on('click', function (e) {
            e.preventDefault();
            showSearch();
          });
          $(close).on('click', function (e) {
            e.preventDefault();
            hideSearch();
          });
        });

        /* =============================== */
        /* RESPONSIVE MENU - START */
        /* =============================== */
        $(function () {
          var pull = $('button.cmn-toggle-switch');
          menu = $('#main-navigation');
          menuHeight = menu.height();

          $(pull).on('click', function (e) {
            e.preventDefault();
            menu.slideToggle("slow");
          });

          $(window).resize(function () {
            var w = $(window).width();
            if (w > 320 && menu.is(':hidden')) {
              menu.removeAttr('style');
            }
          });
        });
        /* =============================== */
        /* RESPONSIVE MENU - END */
        /* =============================== */

        /* ============ */
        /* ACCORDION MENU TEST
        /* ------------ */
        // See original by emre here https://codepen.io/emredenx/pen/ojcxl
        $(document).ready(function ($) {
          $.fn.menumaker = function (options) {
            var flexmenu = $(this), settings = $.extend({
              format: 'dropdown',
              sticky: false
            }, options);
            return this.each(function () {
              $(this).find('.button').on('click', function () {
                $(this).toggleClass('menu-opened');
                var mainmenu = $(this).next('ul');
                if (mainmenu.hasClass('open')) {
                  mainmenu.slideToggle().removeClass('open');
                } else {
                  mainmenu.slideToggle().addClass('open');
                  if (settings.format === 'dropdown') {
                    mainmenu.find('ul').show();
                  }
                }
              });
              flexmenu.find('li ul').parent().addClass('has-sub');
              subToggle = function () {
                flexmenu.find('.has-sub').prepend('<span class="submenu-button"></span>');
                flexmenu.find('.submenu-button').on('click', function () {
                  $(this).toggleClass('submenu-opened');
                  if ($(this).siblings('ul').hasClass('open')) {
                    $(this).siblings('ul').removeClass('open').slideToggle();
                  } else {
                    $(this).siblings('ul').addClass('open').slideToggle();
                  }
                });
              };
              if (settings.format === 'multitoggle') {
                subToggle();
              } else {
                flexmenu.addClass('dropdown');
              }
              if (settings.sticky === true) {
                flexmenu.css('position', 'fixed');
              }

              resizeFix = function () {
                var mediasize = 1200;
                if ($(window).width() > mediasize) {
                  flexmenu.find('ul').show();
                }
                if ($(window).width() <= mediasize) {
                  flexmenu.find('ul').hide().removeClass('open');
                }
              };
              resizeFix();
            });
          };

          $('#header').menumaker({format: 'multitoggle'});

          $('.resource-label').accordion({
            active: false,
            header: "h3",
            collapsible: true,
            heightStyle: "content"
          });

          function onPlayerReady(event) {
            player.playVideo();
            player.mute();

          }

          if ($('.bxslider').children().length > 1) {
            $('.bxslider').bxSlider({
              auto: true,
              autoDelay: 3000,
              pause: 3200,
              touchEnabled: false,
              controls: false
            });
          }

          function createVideo() {
            let browserWidth = $(window).width();
            if (browserWidth > 768) {
              if ($('.with-bg-video').length) {
                var youtubeId = $('.with-bg-video').attr('data-video-id');
                var tag = document.createElement('script');

                tag.src = "https://www.youtube.com/iframe_api";
                var firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                var player = null;
                window.onYouTubeIframeAPIReady = function () {
                  player = new YT.Player('player', {
                    videoId: youtubeId,
                    width: '100%',
                    height: '100%',
                    events: {
                      'onReady': onPlayerReady,
                      'onStateChange': onPlayerStateChange
                    },
                    playerVars: {
                      autoplay: 1,
                      autohide: 1,
                      modestbranding: 1,
                      rel: 0,
                      showinfo: 0,
                      controls: 0,
                      disablekb: 1,
                      enablejsapi: 0,
                      iv_load_policy: 3,
                      suggestedQuality: 'hd720',
                      fs: 0
                    }
                  });
                };


                var done = false;

                function onPlayerStateChange(event) {
                  if (event.data === YT.PlayerState.ENDED) {
                    player.playVideo();
                  }
                }

                function stopVideo() {
                  player.stopVideo();
                }
              }
            }

            $(window).resize(() => {
              browserWidth = $(window).width();
              createVideo();
            });
          }
        }(jQuery));
      },
      // Home page
      'home': {
        init: function () {
          // JavaScript to be fired on the home page
        },
        finalize: function () {
          // JavaScript to be fired on the home page, after the init JS
        }
      },
      // About us page, note the change from about-us to about_us.
      'about_us': {
        init: function () {
          // JavaScript to be fired on the about us page
        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  function onPlayerReady(event) {
    player.playVideo();
    player.mute();
  }

  if ($('.with-bg-video').length) {
    var youtubeId = $('.with-bg-video').attr('data-video-id');
    var tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    var player = null;
    window.onYouTubeIframeAPIReady = function () {
      player = new YT.Player('player', {
        videoId: youtubeId,
        width: '100%',
        height: '100%',
        events: {
          'onReady': onPlayerReady,
          'onStateChange': onPlayerStateChange
        },
        playerVars: {
          autoplay: 1,
          autohide: 1,
          modestbranding: 1,
          rel: 0,
          showinfo: 0,
          controls: 0,
          disablekb: 1,
          enablejsapi: 0,
          iv_load_policy: 3,
          suggestedQuality: 'hd720',
          fs: 0
        }
      });
    };


    var done = false;

    function onPlayerStateChange(event) {
      if (event.data === YT.PlayerState.ENDED) {
        player.playVideo();
      }
    }

    function stopVideo() {
      player.stopVideo();
    }
  }

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

(function ($) {
  $(document).on('facetwp-loaded', function () {
    if (FWP.loaded) {
      $('html, body').animate({
        scrollTop: $('.facetwp-template').offset().top - 150
      }, 500);
    }
  });
})(jQuery);
